<template>
	<form type="POST" v-on:submit.prevent="storeProductosGrupos" ref="productosGruposForm">
		<h3 class="mb-3 text-primary">
			<font-awesome-icon :icon="faPlus" /> {{title}}
		</h3>
		<div class="card">
			<div class="card-body">
				<div class="mb-3">
					<label for="descripcion">Descripción:</label>
					<input type="text" name="descripcion" id="descripcion" class="form-control" placeholder="Ingrese descripción" v-model="form.DESCRIPCION_GRUPO" maxlength="150" required>
					<span v-if="errors.DESCRIPCION_GRUPO" class="text-danger">
						{{errors.DESCRIPCION_GRUPO[0]}}
                    </span>
                </div>
			</div>
			<div class="card-footer">
				<div class="d-flex justify-content-end align-items-center">
					<div class="me-2">
						<router-link :to="{name: 'admin.productos.grupos.index'}" class="btn btn-link">
							Cancelar
						</router-link>
					</div>
					<div>
						<button type="submit" class="btn btn-primary" :disabled="loading || !validateForm">
							<BtnLoading v-if="loadingGrupos" />
                            <span v-else>
                                <font-awesome-icon :icon="faSave" /> Crear grupo
                            </span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>
<script type="text/javascript">
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
	import { faSave, faPlus } from '@fortawesome/free-solid-svg-icons'
	import BtnLoading from '@/components/BtnLoading.vue'
	export default {
		components: {
			FontAwesomeIcon,
			BtnLoading
		},
		data(){
			return {
				faSave,
				faPlus,
				form: {
					DESCRIPCION_GRUPO: ''
				},
				loadingGrupos: false,
				validateForm: false
			}
		},
		methods: {
			storeProductosGrupos(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingGrupos = true
				this.$store.dispatch('storeProductosGrupos', this.form).then((res) => {
					this.$toast.info(`El grupo del producto ha sido creado`)
					this.$router.push({name: 'admin.productos.grupos.index'})
					return res
				}).catch(err => {
					if(err.response){
						if(typeof err.response.data === 'string'){
							this.$toast.error(err.response.data)
						}else{
							this.$toast.error('Error al crear registro')
						}
					}
				}).then(() => {
					this.loadingGrupos = false
				})
			},
			checkValidity(){
				if (!this.$refs.productosGruposForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.productosGruposForm.checkValidity()
				}
			},
		},

		computed:{
			title(){
				return this.$route.meta.title ? this.$route.meta.title : ''
			},

			loading() {
				return this.$store.getters.loading
			},

			errors() {
				return this.$store.getters.errors || {}
			}
		},
		watch: {
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		}
	}
</script>